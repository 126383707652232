import { API_URL } from "./constants";

export const getPage = async (title) => {
  try {
    const response = await fetch(`${API_URL}/pages?slug=${title}`);
    const data = await response.json();
    return data[0];
  } catch (error) {
    throw new Error("Error fetching page data: " + error.message);
  }
};

export const fetchImages = async (title) => {
  try {
    const response = await fetch(`${API_URL}/media`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    const filteredImages = data.filter((image) =>
      image.title.rendered.toLowerCase().includes(title)
    );
    return filteredImages;
  } catch (error) {
    console.error("Error fetching hero images:", error);
  }
};

export const getImagesByCategory = async (category) => {
  try {
    const response = await fetch(`${API_URL}/categories?slug=${category}`);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    if (data.length === 0) {
      throw new Error(`Category "${category}" not found.`);
    }

    const categoryId = data[0].id;

    // Make a second API call to fetch media items by category ID
    const mediaResponse = await fetch(
      `${API_URL}/media?categories=${categoryId}`
    );

    if (!mediaResponse.ok) {
      throw new Error(
        `HTTP error while fetching media items! Status: ${mediaResponse.status}`
      );
    }

    const mediaData = await mediaResponse.json();
    return mediaData;
  } catch (error) {
    console.error(`Error fetching images by category "${category}":`, error);
    throw error;
  }
};
