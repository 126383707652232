import React, { useEffect, useState } from "react";
import { getImagesByCategory } from "../../../utils/utils.js";
import Carousel from "react-bootstrap/Carousel";

const ImageSlider = ({ category, onImageLoad }) => {
  const [loading, setLoading] = useState(true);
  const [heroImages, setHeroImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const images = await getImagesByCategory(category);
        setHeroImages(images);
        setLoading(false);
        onImageLoad();
      } catch (error) {
        console.error("Error fetching hero images:", error);
      }
    };

    fetchImages();
  }, [category, onImageLoad]);

  return (
    <div className="image-slider my-3">
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <Carousel>
          {heroImages.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                src={image.source_url}
                alt={image.alt_text}
                className="d-block w-100 carousel-image"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default ImageSlider;
