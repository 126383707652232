export const API_URL = "https://inkhivetattooco.com/api/wp-json/wp/v2";

export const PAGES = {
  home: "Home",
  about: "About",
  artists: "Artists",
  contact: "Contact",
};

export const ARTISTS = {
  xavier: {
    name: "xavier",
    gallery: "xavier-gallery",
  },
  alex: {
    name: "alex",
    gallery: "alex-gallery",
  },
  alexis: {
    name: "alexis",
    gallery: "alexis-gallery",
  },
  jed: {
    name: "jed",
    gallery: "jed-gallery",
  },
};

export const GALLERY_CATEGORIES = {
  homeSlideshow: "home-slideshow",
  homeGallery: "home-gallery",
  xavier: "xavier-gallery",
  alex: "alex-gallery",
  alexis: "alexis-gallery",
  jed: "jed-gallery",
  artists: "artists-page",
  about: "about-page",
};
