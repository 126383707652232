import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap";
import Loading from "../common/Loading";
import Divider from "../common/Divider";

const Contact = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // You can add your logic to handle form submission here
    console.log("Form data submitted:", formData);
    setShowModal(true);
    setFormData({
      name: "",
      email: "",
      message: "",
    });
    setTimeout(() => {
      setShowModal(false);
      navigate("/");
    }, 3000);
  };

  // fake loading
  setTimeout(() => {
    setLoading(false);
  }, 250);

  return (
    <div className="contact p-3">
      <Loading show={loading} />
      <h1 className="title-text text-center">Contact Us</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name" className="my-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="email" className="my-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="message" className="my-3">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Button
          type="submit"
          className="custom-button w-100 my-3"
          disabled={
            formData.name === "" ||
            formData.email === "" ||
            formData.message === ""
          }
        >
          Submit
        </Button>
      </Form>

      <Modal
        className="custom-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title className="pt-3">Thank You!</Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body className="pb-5 text-center">
          Thank you for reaching out. We'll have someone contact you soon.
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Contact;
