import React, { useState, useEffect } from "react";
import { getPage } from "../../utils/utils";
import Loading from "../common/Loading";
import ImageSlider from "../common/Images/ImageSlider";
import Parser from "html-react-parser";
import Divider from "../common/Divider";
import ImageGallery from "../common/Images/ImageGallery";
import { GALLERY_CATEGORIES, PAGES } from "../../utils/constants";

const Home = () => {
  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageSliderLoading, setImageSliderLoading] = useState(true);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const data = await getPage(PAGES.home);
        setPage(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching page data:", error);
      }
    };

    fetchPageData();
  }, []);

  const handleImageSliderLoad = () => {
    setImageSliderLoading(false);
  };

  return (
    <div className="home">
      <Loading show={loading || imageSliderLoading} />
      <div className="hero">
        <ImageSlider
          category={GALLERY_CATEGORIES.homeSlideshow}
          onImageLoad={handleImageSliderLoad}
        />
      </div>
      <h1 className="title-text text-center mt-3">
        Ink Hive Tattoo Collective
      </h1>
      <Divider />
      <div className="content p-3">
        {page.content ? <>{Parser(page.content.rendered)}</> : null}
      </div>
      <div className="gallery">
        <h2 className="title-text text-center">Gallery</h2>
        <Divider />
        <ImageGallery category={GALLERY_CATEGORIES.homeGallery} />
      </div>
    </div>
  );
};

export default Home;
