import React, { useState, useEffect } from "react";
import { getPage, getImagesByCategory } from "../../utils/utils";
import { GALLERY_CATEGORIES, PAGES } from "../../utils/constants";
import Loading from "../common/Loading";
import Parser from "html-react-parser";
import ArtistGallery from "../common/Images/ArtistGallery";

const Artists = () => {
  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const data = await getPage(PAGES.artists);
        setPage(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching page data:", error);
      }
    };

    fetchPageData();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const images = await getImagesByCategory(GALLERY_CATEGORIES.artists);
        setImages(images);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching hero images:", error);
      }
    };

    fetchImages();
  }, []);

  const getArtists = (pageData) => {
    const parsedHtml = Parser(pageData);
    const headings = parsedHtml.filter((item) => item.type === "h2");
    const paragraphs = parsedHtml.filter((item) => item.type === "p");

    const artists = headings.map((heading, index) => ({
      name: heading.props.children,
      bio: paragraphs[index].props.children,
    }));

    return (
      <>
        {artists.map((artist, index) => {
          // Find the image object that matches the artist's name
          const matchingImage = images.find(
            (image) => image.slug.toLowerCase() === artist.name.toLowerCase()
          );

          // Use a default image URL if no matching image is found
          const imageUrl = matchingImage
            ? matchingImage.source_url
            : "http://inkhivetattooco.com/api/ihtc/wp-content/uploads/2023/09/default-image.jpg";

          return (
            <div key={index} className="artist">
              <div className="hexagon-container">
                <div className="hexagon">
                  <ArtistGallery artist={artist.name} imageUrl={imageUrl} />
                  {/* <div
                    className="hexagon-inner"
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                    }}
                  ></div> */}
                </div>
              </div>
              <h2 className="text-center title-text">{artist.name}</h2>
              <p className="text-center custom-fade fade-in">
                Click image for more work from {artist.name}
              </p>
              <p className="text-center p-3">{artist.bio}</p>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="artists">
      <Loading show={loading} />
      <h1 className="title-text text-center my-3">
        {page.content && page.title ? page.title.rendered : null}
      </h1>
      <div className="content p-3">
        {page.content ? (
          <>{getArtists(page.content.rendered)}</>
        ) : (
          "no page data"
        )}
      </div>
    </div>
  );
};

export default Artists;
