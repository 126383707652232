import React from "react";
import Header from "./components/common/Header";
import Routing from "./components/routes/Routes";
import Footer from "./components/common/Footer/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import "../src/style/style.scss";
import { Container, Col, Row } from "react-bootstrap";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Container>
          <Row>
            <Col>
              <Routing />
            </Col>
          </Row>
        </Container>
        <Footer />
      </Router>
    </>
  );
}

export default App;
