import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import IconInstagram from "../../../assets/img/instagram-logo-svgrepo-com.svg";
import "./Footer.scss";

const API_KEY = "AIzaSyDnajg-VGBF4d0g80qxmZrs3EAFPHtVklE";

// function initMap() {
//   const myLatLng = {
//     lat: 33.42338943481445,
//     lng: -111.7216796875,
//   };
//   const map = new google.maps.Map(document.getElementById("gmp-map"), {
//     zoom: 14,
//     center: myLatLng,
//     fullscreenControl: false,
//     zoomControl: true,
//     streetViewControl: false,
//   });
//   new google.maps.Marker({
//     position: myLatLng,
//     map,
//     title: "My location",
//   });
// }

const Footer = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
  });
  const center = useMemo(
    () => ({
      lat: 33.42338943481445,
      lng: -111.7216796875,
    }),
    []
  );
  return (
    <footer>
      <Container>
        <Row>
          <Col lg={6} xs={12}>
            <div className="mb-4">
              <h3>Location</h3>
              <GoogleMap
                mapContainerClassName="map-container"
                zoom={16}
                center={center}
              >
                <Marker position={center} />
              </GoogleMap>
            </div>
          </Col>

          <Col lg={6} xs={12}>
            <div>
              <h3>Contact</h3>
              <p>
                Email:{" "}
                <a
                  className="footer-link"
                  href="mailto:inkhivetattooco@gmail.com"
                >
                  inkhivetattooco@gmail.com
                </a>{" "}
              </p>
              <p>
                Address:{" "}
                <a
                  className="footer-link"
                  href="https://www.google.com/maps?q=5050+E+University+Dr+Ste.108+Mesa+AZ+85205"
                  target="_blank"
                >
                  5050 E University Dr Ste.108 Mesa AZ 85205
                </a>
              </p>
              <p>
                Phone:{" "}
                <a
                  className="footer-link"
                  href="tel:4808681122"
                  target="_blank"
                >
                  (480) 868-1122
                </a>
              </p>
              <div className="social-icons">
                <a
                  target="_blank"
                  href="https://www.instagram.com/inkhivetattooco/"
                >
                  <img src={IconInstagram} alt="instagram" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
