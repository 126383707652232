import React, { useState, useEffect } from "react";
import { getImagesByCategory } from "../../../utils/utils";
import "./ImageGallery.scss";
import { Modal } from "react-bootstrap";

const ImageGallery = ({ category }) => {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const images = await getImagesByCategory(category);
        setImages(images);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching hero images:", error);
      }
    };

    fetchImages();
  }, [category]);

  function createArrayOfArrays(arr) {
    const result = [];
    let currentIndex = 0;
    const pattern = [3, 4]; // Your pattern: 3 entries, 4 entries, ...

    while (currentIndex < arr.length) {
      const currentPattern = pattern.shift(); // Get the next pattern length

      if (!currentPattern) {
        break; // No more patterns to apply
      }

      result.push(arr.slice(currentIndex, currentIndex + currentPattern));
      currentIndex += currentPattern;
      pattern.push(currentPattern); // Put the used pattern back to the end
    }

    return result;
  }

  const galleryImages = createArrayOfArrays(images);

  const displayImages = (images) => {
    if (!images) return null;

    return images.map((imageGroup, rowIndex) => (
      <div
        key={rowIndex}
        className={rowIndex % 2 === 0 ? "hex_row_odd" : "hex_row_even"}
      >
        {imageGroup.map((image, index) => (
          <div
            key={index}
            className="center"
            onClick={() => setSelectedImage(image.source_url)}
          >
            <div className="hexagon">
              <div className="hex1">
                <div
                  className="hex2"
                  style={{
                    backgroundImage: `url('${image.source_url}')`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  const displayImagesMobile = (images) => {
    return images.map((img, index) => {
      return (
        <div
          key={index}
          className="hexagon-container-mobile"
          onClick={() => setSelectedImage(img.source_url)}
        >
          <div className="hexagon-mobile">
            <div
              className="hexagon-inner-mobile"
              style={{
                backgroundImage: `url('${img.source_url}')`,
              }}
            ></div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`hexagon-gallery ${isMobile ? "mobile" : ""}`}>
      {isMobile ? (
        <section>{displayImagesMobile(images)}</section>
      ) : (
        <section>{displayImages(galleryImages)}</section>
      )}
      <Modal
        className="custom-modal image-modal"
        show={selectedImage !== null}
        onHide={() => setSelectedImage(null)}
        centered
      >
        <Modal.Body>
          <img src={selectedImage} alt="selected" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImageGallery;
