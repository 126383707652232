import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Image } from "react-bootstrap";

function Navigation() {
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <div className="custom-navbar-button">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/" onClick={handleLinkClick}>
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/about" onClick={handleLinkClick}>
            About
          </Nav.Link>
          <Nav.Item className="d-none d-md-block">
            <Nav.Link as={Link} to="/" onClick={handleLinkClick}>
              <Image className="logo" src="/img/logo.png" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Link as={Link} to="/artists" onClick={handleLinkClick}>
            Artists
          </Nav.Link>
          <Nav.Link as={Link} to="/contact" onClick={handleLinkClick}>
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Nav.Item className="d-md-none logo">
        <Image src="/img/logo.png" />
      </Nav.Item>
    </Navbar>
  );
}

export default Navigation;
