import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getPage } from "../../utils/utils";
import Loading from "../common/Loading";
import Parser from "html-react-parser";
import Divider from "../common/Divider";
import { PAGES, GALLERY_CATEGORIES } from "../../utils/constants";
import { getImagesByCategory } from "../../utils/utils";

const About = () => {
  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const data = await getPage(PAGES.about);
        setPage(data);
      } catch (error) {
        console.error("Error fetching page data:", error);
      }
    };

    fetchPageData();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const images = await getImagesByCategory(GALLERY_CATEGORIES.about);
        setImages(images);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching hero images:", error);
      }
    };

    fetchImages();
  }, []);

  const generateSections = (pageData) => {
    if (!images || images.length === 0) return;
    const parsedHtml = Parser(pageData);

    // Extract text content from paragraphs and store them in an array
    const paragraphs = parsedHtml
      .filter((item) => item.type === "p")
      .map((paragraph) => paragraph.props.children);

    // Initialize an empty array to store the sections
    const sections = [];

    // Loop through paragraphs and add them to sections
    for (let i = 0; i < paragraphs.length; i++) {
      const paragraphText = paragraphs[i];
      const backgroundImage = images[i].source_url
        ? images[i].source_url
        : "http://inkhivetattooco.com/api/ihtc/wp-content/uploads/2023/09/default-image.jpg";

      // Create a section for each paragraph text
      sections.push(
        <Container key={i} className="my-4">
          <Row>
            <Col xs={12} lg={6} className={i % 2 === 0 ? "order-lg-2" : ""}>
              <div className="h-50vh d-flex justify-content-center align-items-center p-3">
                <p>{paragraphText}</p>
              </div>
            </Col>
            <Col xs={12} lg={6} className={i % 2 === 0 ? "" : "order-lg-2"}>
              <div
                className="h-50vh background-image"
                style={{ backgroundImage: `url(${backgroundImage})` }}
              ></div>
            </Col>
          </Row>
        </Container>
      );
    }

    return sections;
  };

  return (
    <div className="about my-3">
      <Loading show={loading} />
      <h1 className="title-text text-center">About Us</h1>
      <div className="content p-3">
        {page.content ? (
          <>{generateSections(page.content.rendered)}</>
        ) : (
          "no page data"
        )}
      </div>
    </div>
  );
};

export default About;
