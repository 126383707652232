import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { GALLERY_CATEGORIES } from "../../../utils/constants";
import ImageSlider from "./ImageSlider";

const ArtistGallery = ({ artist, imageUrl }) => {
  const [showModal, setShowModal] = useState(false);

  const handleGalleryClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {console.log("artist", artist)}
      <div
        className="hexagon-inner artist-gallery"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
        onClick={handleGalleryClick}
      ></div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="custom-modal image-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">{`${artist}'s Work`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageSlider category={GALLERY_CATEGORIES[artist.toLowerCase()]} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ArtistGallery;
